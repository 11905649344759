// sass-lint:disable class-name-format

@charset 'UTF-8';

@import 'variables';

@font-face {
  //light
  font-family: 'WF-029813-009945-000002';
  font-style: normal;
  font-weight: normal;
  src: url('#{$default-path}/WF-029813-009945-000002.eot');
  src: url('#{$default-path}/WF-029813-009945-000002.eot?#iefix') format('embedded-opentype'), url('#{$default-path}/WF-029813-009945-000002.woff') format('woff'), url('#{$default-path}/WF-029813-009945-000002.ttf') format('truetype');
}

@font-face {
  // demi
  font-family: 'WF-029813-009945-000017';
  font-style: normal;
  font-weight: normal;
  src: url('#{$default-path}/WF-029813-009945-000017.eot');
  src: url('#{$default-path}/WF-029813-009945-000017.eot?#iefix') format('embedded-opentype'), url('#{$default-path}/WF-029813-009945-000017.woff') format('woff'), url('#{$default-path}/WF-029813-009945-000017.ttf') format('truetype');
}

@font-face {
  // book
  font-family: 'WF-029813-009945-000007';
  font-style: normal;
  font-weight: normal;
  src: url('#{$default-path}/WF-029813-009945-000007.eot');
  src: url('#{$default-path}/WF-029813-009945-000007.eot?#iefix') format('embedded-opentype'), url('#{$default-path}/WF-029813-009945-000007.woff') format('woff'), url('#{$default-path}/WF-029813-009945-000007.ttf') format('truetype');
}

.WF-029813-009945-000002 {
  font-family: 'WF-029813-009945-000002';
  font-size: 28pt;
  font-weight: normal;
  text-align: center;
}

.WF-029813-009945-000017 {
  font-family: 'WF-029813-009945-000017';
  font-size: 28pt;
  font-weight: normal;
  text-align: center;
}

.WF-029813-009945-000007 {
  font-family: 'WF-029813-009945-000007';
  font-size: 28pt;
  font-weight: normal;
  text-align: center;
}

@mixin fedra-default-light {
  font-family: 'WF-029813-009945-000002';
}

@mixin fedra-default-book {
  font-family: 'WF-029813-009945-000007';
}

@mixin fedra-default-demi {
  font-family: 'WF-029813-009945-000017';
}

.fedra-default-light {
  @include fedra-default-light();
}

.fedra-default-book {
  @include fedra-default-book();
}

.fedra-default-demi {
  @include fedra-default-demi();
}
